// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-cookies-js": () => import("./../src/pages/error/cookies.js" /* webpackChunkName: "component---src-pages-error-cookies-js" */),
  "component---src-pages-error-declined-js": () => import("./../src/pages/error/declined.js" /* webpackChunkName: "component---src-pages-error-declined-js" */),
  "component---src-pages-error-reauth-js": () => import("./../src/pages/error/reauth.js" /* webpackChunkName: "component---src-pages-error-reauth-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-logs-js": () => import("./../src/pages/logs.js" /* webpackChunkName: "component---src-pages-logs-js" */),
  "component---src-pages-order-js": () => import("./../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-payment-list-js": () => import("./../src/pages/payment/list.js" /* webpackChunkName: "component---src-pages-payment-list-js" */),
  "component---src-pages-payment-manage-js": () => import("./../src/pages/payment/manage.js" /* webpackChunkName: "component---src-pages-payment-manage-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-settings-js": () => import("./../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

